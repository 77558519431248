import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import ModalWrapper from "./ModalWrapper";
import Logo from "../assets/images/logoroyalking.png";
import AviatorLogo from "../assets/images/icons/aviator-logo.webp";
import RegistrationIcon from "../assets/images/icons/registration-icon.webp";
import ArrowRight from "../assets/images/icons/arrow-right.webp";
import GiftIcon from "../assets/images/icons/gift-icon.webp";
import Bonuses from "../assets/images/icons/bonuses.webp";
import LuckyDrawIcon from "../assets/images/icons/lucky-draw-icon.svg";
import WinningItem from "../assets/images/icons/royalEnfield.webp";
import WindowsImg from "../assets/images/icons/windows-img.svg";
import AndroidIcon from "../assets/images/icons/android-apk.svg";
import AppleIcon from "../assets/images/icons/apple-apk.svg";
import UpArrow from "../assets/images/icons/up-arrow.svg";
import LanguageChange from "../views/components/LanguageChange";
import GamesSearchModal from "../views/components/GamesSearchModal";

const headerPages = [
  "home",
  "betby",
  "sports",
  "casinogames",
  "livecasino",
  "aviator",
  "evolution",
  "ezugi",
  "supernowa",
  "vivo",
  "xpg",
  "worldcasino",
  "qtech",
  "/",
];

const Header = () => {
  const { appDetails } = useSelector((state) => state.app);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (currentScrollTop > lastScrollTop && currentScrollTop > 0) {
        // Scrolling down
        setIsSticky(true);
      } else if (currentScrollTop <= 0) {
        // Remove sticky class when scrolled all the way up
        setIsSticky(false);
      }
      setLastScrollTop(currentScrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const [isproviderActive, setIsproviderActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const inlineStyle = isproviderActive ? { overflowX: "clip" } : {};
  const handleProvidersClick = () => {
    const isMobile = window.innerWidth < 992;

    if (isMobile) {
      setShowModal(true);
      document.body.classList.add("modal-providers");
      document.documentElement.style.overflow = "hidden";
    } else {
      setIsproviderActive(!isproviderActive);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    document.body.classList.remove("modal-providers");
    document.documentElement.style.overflow = "auto";
  };

  const menuItems = [
    { name: "Home", path: "/", activeKey: "/" },
    { name: "Sports", path: "/betby", activeKey: "betby" },
    { name: "Ludo", path: "/casino/km-ludo", activeKey: "ludo", isLudo: true },
    { name: "Inplay", path: "/sports/inplay", activeKey: "sports" },
    { name: "Casino", path: "/casinogames", activeKey: "casinogames" },
    { name: "Live-games", path: "/livecasino", activeKey: "livecasino" },
    {
      name: "Aviator",
      path: "casino/spribe/aviator",
      activeKey: "aviator",
      isLogo: true,
      logo: AviatorLogo,
    },
  ];
  const providers = [
    { name: "Evolution", path: "/casino/evolution", activeKey: "evolution" },
    { name: "Ezugi", path: "/casino/ezugi", activeKey: "ezugi" },
    { name: "Supernowa", path: "/casino/supernowa", activeKey: "supernowa" },
    { name: "Vivo", path: "/casino/vivo", activeKey: "vivo" },
    { name: "XPG", path: "/casino/xpg", activeKey: "xpg" },
    {
      name: "Worldcasino",
      path: "/casino/worldcasino",
      activeKey: "worldcasino",
    },
    { name: "Qtech", path: "/casino/qtech", activeKey: "qtech" },
  ];

  return (
    <>
      <header className="header beforeheader">
        <div className="top_head d-none d-lg-block">
          <div className="inner-box">
            <div className="left-section">
              <a href="/trending-games" className="vip-level">
                <span>Trending Games!</span>
                <span className="vip-icon"></span>
                <img src={ArrowRight} alt="right arrow" />
              </a>
            </div>

            <div className="right-section">
              <div className="vip-gift">
                <a href="/promotions" className="promotion">
                  <div className="img-box">
                    <img src={GiftIcon} alt="promotions" />
                  </div>
                  <span>Promotions and bonuses</span>
                  <img src={Bonuses} alt="bonuses" className="bonus" />
                </a>
                <a href="/lucky-draw" className="lucky-draw">
                  <div className="img-box">
                    <img src={LuckyDrawIcon} alt="lucky draw" />
                  </div>
                  <span> Lucky Drive</span>
                  <img
                    src={WinningItem}
                    alt="winning item"
                    className="win-itme"
                  />
                </a>
              </div>
              <a
                href="https://dl.dropbox.com/scl/fi/08so7ic8g97m0v3mjybho/Royalking.apk?rlkey=8ze8abqfga298vdemsshflxaq&st=el5bpjof&dl=0"
                download="https://dl.dropbox.com/scl/fi/08so7ic8g97m0v3mjybho/Royalking.apk?rlkey=8ze8abqfga298vdemsshflxaq&st=el5bpjof&dl=0"
              >
                <div className="vip-apk">
                  <div className="window-apk">
                    <div>
                      <h6>Application</h6>
                      <p>for Windows</p>
                    </div>
                    <a href="javascript:void(0)">
                      <img src={WindowsImg} alt="windows" />
                    </a>
                  </div>
                  <div className="mobile-apk">
                    <div className="app-btn">
                      <img src={AndroidIcon} alt="android apk" />
                    </div>
                    <div className="app-btn">
                      <img src={AppleIcon} alt="apple apk" />
                    </div>
                    <div className="tooltip-content d-none">
                      <div className="up-arrow">
                        <img src={UpArrow} alt="up arrow" />
                      </div>
                      <div className="content-box">
                        <p>
                          Visit the site from your smartphone, install the
                          application right now and get INR 200 to the bonus
                          account!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>

              <div className="language">
                <LanguageChange />
              </div>
            </div>
          </div>
        </div>

        <div className={`bottom_head ${isSticky ? "sticky" : ""}`}>
          <div className="inner-box">
            <div className="logo">
              <a href="/">
                {/* <img src={appDetails?.LOGO_URL} alt="Logo" height={60} /> */}
                <img src={Logo} alt="Logo" />
              </a>
            </div>

            <div className="head-links">
              <ul style={inlineStyle}>
                {menuItems.map((item) => (
                  <li key={item.name} className={item.isLudo ? "ludo" : ""}>
                    <a
                      href={item.path}
                      className={`
                      ${
                        location.pathname === item.path ||
                        activePage === item.activeKey
                          ? "active"
                          : ""
                      }
                      ${item.name === "Aviator" ? "game-logo" : ""}
                    `}
                    >
                      <span>
                        {item.isLogo ? (
                          <img src={item.logo} alt={item.name} />
                        ) : (
                          item.name
                        )}
                      </span>
                    </a>
                  </li>
                ))}
                <li className="providers d-none">
                  <a href="javascript:void(0)" onClick={handleProvidersClick}>
                    <span>Games by provider</span>
                  </a>
                  <ul
                    className={`providers-games d-none d-md-flex ${
                      isproviderActive ? "active" : ""
                    }`}
                  >
                    {providers.map((provider) => (
                      <li key={provider.name}>
                        <a
                          href={provider.path}
                          className={
                            activePage === provider.activeKey ? "active" : ""
                          }
                        >
                          <span>{provider.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <a
                    href="/casino/evolution"
                    className={activePage == "evolution" ? "active" : ""}
                  >
                    <span>Evolution</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/casino/ezugi"
                    className={activePage == "ezugi" ? "active" : ""}
                  >
                    <span>Ezugi</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/casino/supernowa"
                    className={activePage == "supernowa" ? "active" : ""}
                  >
                    <span>Supernowa</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/casino/vivo"
                    className={activePage == "vivo" ? "active" : ""}
                  >
                    <span>Vivo</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/casino/xpg"
                    className={activePage == "xpg" ? "active" : ""}
                  >
                    <span>XPG</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/casino/worldcasino"
                    className={activePage == "worldcasino" ? "active" : ""}
                  >
                    <span>Worldcasino</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/casino/qtech"
                    className={activePage == "qtech" ? "active" : ""}
                  >
                    <span>Qtech</span>
                  </a>
                </li>
              </ul>

              <div className="d-lg-none">
                <GamesSearchModal />
              </div>
            </div>

            <div className="rightSec ms-lg-auto">
              <Button
                variant=""
                onClick={() => {
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
                className="btn_signin"
              >
                Sign In
              </Button>
              <Button
                variant=""
                onClick={() => {
                  handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                }}
                className="btn_signup"
              >
                <span className="d-none d-lg-inline-flex">
                  <img src={RegistrationIcon} alt="registration" />
                </span>
                Sign Up
              </Button>
            </div>
          </div>
        </div>

        <Modal
          className="providers-list"
          show={showModal}
          onHide={handleModalClose}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body>
            <div className="providers-link">
              <ul>
                {providers.map((provider) => (
                  <li key={provider.name}>
                    <a
                      href={provider.path}
                      className={
                        activePage === provider.activeKey ? "active" : ""
                      }
                    >
                      <span>{provider.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
          />
        )}
      </header>
    </>
  );
};

export default Header;
